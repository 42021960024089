<template>
	<!-- 登录 -->
	<div>
		<p class="title">登录</p>
		<van-image round width="7rem" height="7rem" :src="require('./logo.png')" fit="none" />
		<van-form ref="loginRules">
			<van-field
				v-model="account"
				label="用户名"
				placeholder="请填写用户名"
				clearable
				:rules="[{ required: true }]"
			/>
			<van-field
				v-model="password"
				type="password"
				label="密码"
				placeholder="请填写密码"
				clearable
				:rules="[{ required: true }]"
			/>
			<van-field
				v-model="code"
				center
				clearable
				label="验证码"
				placeholder="请填写验证码"
				:rules="[{ required: true }]"
			>
				<template #extra>
					<van-image width="5rem" height="2rem" :src="imageSrc" @click="getCode" />
					<!-- <van-image width="100" height="50" src="https://img01.yzcdn.cn/vant/cat.jpeg"/> -->
				</template>
			</van-field>
			<div style="margin: 1.6rem">
				<van-button round block type="info" @click="onLogin">登录</van-button>
			</div>
		</van-form>
	</div>
</template>

<script src="https://pv.sohu.com/cityjson?ie=utf-8"></script>

<script>
	import { Toast } from 'vant';
	import { uuid } from 'vue-uuid';

	let uuidR;
	export default {
		name: 'Login',
		data() {
			return {
				account: '',
				password: '',
				code: '',
				imageSrc: '',
				ip: '',
			};
		},
		created() {
			this.ip = returnCitySN['cip'];
			this.getCode();
			let code = this.getUrlCode('code');
			if (code !== undefined && code !== null) {
				//调用后台获取openId
				this.axios({
					method: 'post',
					url: '/v1/h5/user-info/set-open-id',
					params: {
						code: code,
					},
				});
				this.$router.push('/');
			}
		},
		methods: {
			getCode() {
				uuidR = uuid.v1();
				this.imageSrc = '/api/v1/h5/user-info/get-verification?clientUid=' + uuidR;
			},
			getUrlCode(name) {
				var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
				var r = window.location.search.substring(1).match(reg);
				if (r != null) return decodeURI(r[2]);
				return null;
			},
			onLogin() {
				this.$refs.loginRules.validate().then(() => {
					this.axios({
						method: 'post',
						url: '/v1/h5/user-info/login',
						data: {
							account: this.account,
							clientUid: uuidR,
							password: this.password,
							code: this.code,
							ip: this.ip,
						},
					}).then((res) => {
						if (res.data.code === 200) {
							Toast.success('登录成功');
							localStorage.setItem('token', res.headers.token);
							localStorage.setItem('userName', res.data.data.userName);
							localStorage.setItem('userId', res.data.data.id);
							localStorage.setItem('groupId', res.data.data.groupId);
							localStorage.setItem('roleList', JSON.stringify(res.data.data.roleList));
							localStorage.setItem('permissionList', JSON.stringify(res.data.data.permissionList));
							localStorage.setItem('receivingOrder', res.data.data.receivingOrder);
							if (
								res.data.data.openId === undefined ||
								res.data.data.openId === null ||
								res.data.data.openId === ''
							) {
								//获取openId
								const appId = 'wx3eb9ede59b40d88a';
								const url = window.location.href;
								let code = this.getUrlCode('code');
								if (code === undefined || code === null || code === '') {
									let href =
										'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
										appId +
										'&redirect_uri=' +
										encodeURIComponent(url) +
										'&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect'; //打开这个链接，你的url后面就会跟上code的参数
									window.location.href = href;
								}
							} else {
								this.$router.push('/');
							}
						} else {
							Toast.fail(res.data.message);
						}
					});
				});
			},
		},
	};
</script>

<style scoped>
	.title {
		margin-left: 1.2rem;
		height: 2rem;
		text-align: left;
		font-size: 26px;
	}
	/deep/.van-icon__image {
		width: 6rem;
		height: 3rem;
	}
</style>
